export default {
    title: 'dark',

    colors: {
        primary: '#240046',
        secondary: '#3C096C',
        tertiary: '#5A189A',

        white: '#FFF',
        black: '#000',
        gray: '#BFBFBF',

        success: '#4E41F0',
        info: '#E0AAFF',
        warning: '#EF4123',
    },
};
import React from "react";

import { Grid } from './styles';

import Aside from "../Aside";
import MaiHeader from "../MainHeader";
import Content from "../Content";


type Props = {
    children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <Grid>
            <Aside />
            <MaiHeader />
            <Content>
                { children }
            </Content>
        </Grid>  
    );
}

export default Layout;
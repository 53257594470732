import React from "react";

import {
    MdAreaChart,
    //MdInsights,
    //MdQueryStats,
    MdArrowDownward,
    MdArrowUpward,
    MdExitToApp
} from 'react-icons/md';

import logoImg from '../../assets/logo2.png'

import { 
    Container, 
    Header, 
    LogoImg,
    Title,
    MenuContainer,
    MenuItemLink
} from './styles';

const Aside: React.FC = () => {
    return (
        <Container>
            <Header>
                <LogoImg src={logoImg} alt="Logo Minha Carteira" />
                <Title></Title>
            </Header>

            <MenuContainer>
                <MenuItemLink href="/dashboard">
                    <MdAreaChart />
                    DASHBOARD
                </MenuItemLink>
                <MenuItemLink href="/list/entry-balance">
                    <MdArrowUpward />
                    ENTRADAS
                </MenuItemLink>
                <MenuItemLink href="/list/exit-balance">
                    <MdArrowDownward />
                    SAÍDAS
                </MenuItemLink>
                <MenuItemLink href="#">
                    <MdExitToApp />
                    SAIR
                </MenuItemLink>
            </MenuContainer>
        </Container>  
    );
}

export default Aside;
import styled from 'styled-components';

export const Container = styled.div `
    grid-area: AS;
    background-color: ${props => props.theme.colors.secondary};
    padding-left: 20px;

    border-right: 1px solid ${props => props.theme.colors.gray};
`;

export const Header = styled.header `
    height: 90px;
    display: flex;
    align-items: center;
`; 

export const LogoImg = styled.img `
    height: 56px;
    width: 208px;
`;

export const Title = styled.h3 `
    color: ${props => props.theme.colors.white};
    margin-left: 10px;
`;

export const MenuContainer = styled.nav `
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

export const MenuItemLink = styled.a `
    color: ${props => props.theme.colors.info};
    text-decoration: none;
    font-size: 13.3px;

    margin: 10px 5px;
    display: flex;
    align-items: center;

    transition: opacity .3s;

    &:hover{
        //color: white;
        //transition: opacity .5s;
        opacity: .7;
    }

}
    > svg {
        font-size: 22px;
        margin-right: 8px;
    }
`;